body {
  font-family: "Work Sans", sans-serif;
  background-color: #ffffff;
  background-image: url("./../public/asset/images/homepage.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
}
button:hover,
button:focus,
button:active {
  color: #ffffff !important; /* Keep text color the same */
  outline: none; /* Remove the default outline on focus */
}
button ::selection {
  color: #ffffff !important; /* Keep text color the same */
  outline: none; /* Remove the default outline on focus */
}

@font-face {
  font-family: Gotham;
  src: url("./../public/asset/font/Gotham-Font/Gotham-Bold.otf");
}

.countDownContainer {
  position: absolute;
  text-align: center;
  top: 200px;
  left: 100px;
  transform: translateY(-50%);
}

.main-heading {
  font-size: 5vh !important;
  font-weight: bold !important;
  color: #3939a0;
  font-family: "Work Sans", sans-serif !important;
}

.sub-heading {
  font-size: 3vh !important;
  font-family: "Yantramanav", sans-serif;
  color: #4e4e4e;
  font-weight: 300 !important;
}

.sub-para {
  font-size: 2.3vh !important;
  font-family: "Yantramanav", sans-serif;
  color: #4e4e4e;
  font-weight: 500 !important;
}
.flag-cont {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  flex-direction: column !important;
  position: relative !important;
  background-color: "#fff" !important;
}
.flag-card-head {
  color: #164a7c;
  font-size: 2.5vh;
  font-weight: bold;
  font-family: "Work Sans", sans-serif;
}
.flag-card-num {
  color: #002f74;
  font-family: Oswald;
  font-weight: semibold;
  font-size: 3.1em !important;
  /* -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black; */
}
.flag_video {
  display: flex;
  justify-content: center;
  height: 40vh;
  border-radius: 5px;
}

.contribute_btn {
  border-radius: 50px;
}
.css-1a1vzvn-MuiPaper-root-MuiAppBar-root {
  z-index: 100 !important;
}
.pledge-btn {
  background-color: #ec3e2b;
  color: #fff;
  border-radius: 50px;
  padding: 10px 20px;
  font-size: 1.3rem;
  font-weight: 500;
  margin-top: 20px;
}
.pledge-btn:hover {
  background-color: #ec3e2b;
  color: #fff;
}

#cancelBtn {
  background-color: crimson;
}
.modalBackground-certificate {
  width: 100vw;
  height: 100vh;
  background-color: rgba(200, 200, 200, 0);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-container-certificate {
  font-family: "Gotham", sans-serif;
  /* width: 50vw !important; */
  /* height: 75vh; */
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 25px;
  margin: 1%;
}
.popup-header-certificate {
  position: relative;
  box-sizing: border-box;
  border-radius: 15px;
  justify-items: center;
}
.popup-image-certificate {
  height: 60vh;
  left: 50%;
}
.certi-cont {
  margin-top: 2%;
  display: flex;
  justify-content: center;
}
.certi-name {
  position: absolute;
  top: 45%;
  color: #bd8e33;
  top: 43%;
  font-size: 5vh;
  font-weight: 800;
  font-family: "Dancing Script", cursive;
}
.share {
  height: 3vh;
  cursor: pointer;
}
.green {
  color: #59823e;
}
.certificate-canvas {
  height: 60vh;
  width: auto;
  box-shadow: rgba(128, 128, 128, 0.48) 1px 4px 16px;
}

.share-div-icon {
  margin-top: 10%;
  margin-bottom: 10%;
  display: flex;
  justify-content: center;
}

.orange {
  color: #ff7a00;
}
.green_t {
  color: #1de346;
}

hr.org {
  border-top: 4px solid #ff6301;
  opacity: inherit;
  margin: 0.25rem 0 !important;
}
hr.grn {
  border-top: 4px solid #229e3d;
  opacity: inherit;
  margin: 0.25rem 0 !important;
}

@media (max-width: 970px) {
  .flag-card-head {
    font-size: 15px !important;
  }
  .flag-card-num {
    font-size: 25px !important;
  }
  .modalPop {
    width: 70vw !important;
  }
  .certi-name {
    font-size: 3vh !important;
  }
  .popup-image-certificate {
    height: auto !important;
  }
  .certificate-canvas {
    height: auto !important;
    width: 85vw;
  }
  .thankyou {
    position: absolute;
    left: 0%;
    margin-left: 25%;
    margin-top: 15%;
    font-size: 4vh;
  }
  .thankyou_mal {
    position: absolute;
    left: 0%;
    margin-left: 4%;
    margin-top: 15%;
    font-size: 4vh;
  }
  .modalContainer-thank {
    font-family: "Gotham", sans-serif;
    width: min-content;
    height: auto;
    border-radius: 12px;
    background-color: white;
    box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;

    position: fixed;
    top: 0;
    margin: 20% 1% 0% 1%;
    padding: 20px;
    z-index: 1000;
  }
  .modalBackground-certificate {
    width: 100vw;
    height: 100vh;
    background-color: rgba(200, 200, 200, 0);
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .modal-container-certificate {
    padding: 16px;
    margin: 0% 1%;
  }
  .flag_video {
    display: flex;
    justify-content: center;
    height: 25vh;
  }

  .about_main_title {
    font-family: "Roboto", sans-serif !important;
    color: #0e082c !important;
    margin-bottom: 0;
    font-size: 27px !important;
    font-weight: bold;
  }
  .about_main_para {
    font-family: "Inter", sans-serif !important;
    color: #353535 !important;
    font-weight: 500;
    font-size: 17px !important;
  }
}
.pledge_quote {
  color: #274005 !important;
  font-size: 2.5vh !important;
  font-family: Poppins;
  font-weight: bold !important;
}

.black_btn {
  background-image: url(./../public/asset/images/black_btn.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent !important;
  color: #fff !important;
  font-size: 1.2rem;
  font-weight: 500;
  padding: 10px 20px;
  margin-top: 20px;
  min-width: 200px !important;
  min-height: 60px !important;
}
.upload_selfie_title {
  font-size: 20px !important;
  font-family: Poppins;
  font-weight: bold !important;
  color: #274005 !important;
}
.orange_btn {
  background-image: url(./../public/asset/images/orange_btn.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent !important;
  color: #fff !important;
  font-size: 1rem;
  font-weight: 500;
  padding: 10px 20px;
  margin-top: 20px;
  min-width: 300px !important;
  min-height: 60px !important;
  outline: none !important;
  border: none !important;
}

.orange_btn:hover,
.orange_btn:focus,
.orange_btn::selection,
.orange_btn:active {
  color: #ffffff !important; /* Keep text color the same */
  outline: none; /* Remove the default outline on focus */
  border: none !important;
}
.green_btn {
  background-image: url(./../public/asset/images/green_btn.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent !important;
  color: #fff;
  font-size: 1.2rem;
  font-weight: 500;
  padding: 10px 20px;
  margin-top: 20px;
  min-width: 300px !important;
  min-height: 60px !important;
}
.flag_hoist_title {
  font-size: 2vh !important;
  font-family: Poppins;
  font-weight: bold !important;
  color: #0e082c !important;
}
.landing_section_title {
  font-size: 3vh !important;
  font-family: Poppins;
  font-weight: bold !important;
  color: #0e082c !important;
}
.main-heading {
  font-size: 7vh !important;
  font-weight: 700 !important;
  color: #1de446 !important;
  font-family: "Inter" !important;
  text-shadow: 2px 2px 3px #000000;
}
.green_text {
  color: #1de446 !important;
}
.sub-heading {
  font-family: Gotham !important;
  font-size: 2.5vh !important;
  font-weight: 700 !important;
  text-shadow: 2px 2px 3px #000000;
}
.error-message {
  color: red;
  font-size: 1.5vh;
  font-weight: 500;
}
.text-grey {
  color: #919090;
  font-size: 10px;
}
.tweet-cont {
  display: flex;
  overflow-x: auto;
}
.ril__toolbar {
  height: 50px;
  top: 72px !important;
}
.transition-md {
  transition: all 0.5s ease-in-out;
}
.upload_selfie_note {
  font-size: 1.5vh !important;
  font-family: Poppins;
  font-weight: 500 !important;
  color: #274005 !important;
}
.css-smes5z-MuiFormControl-root-MuiTextField-root .MuiOutlinedInput-input {
  background-color: #fff !important;
}
.css-mvp1np-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  background-color: #fff !important;
}
.css-eo0t6s {
  background-color: #fff !important;
}
.css-14ujly6 {
  background-color: #fff !important;
}
.MuiSelect-select {
  font-size: 12px !important;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spin {
  animation: spin 10s linear infinite;
}

.about_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.about_card {
  box-shadow: #80808094 0px 0px 16px !important;
  background-color: white !important;
  margin: 3rem 0;
  border-radius: 16px;
}

.about_card_title {
  font-family: "Inter", sans-serif !important;
  color: #274005 !important;
  font-weight: bold;
  font-size: 20px;
  text-transform: capitalize;
  margin-bottom: 0;
  padding: 3px;
}
.about_card_para {
  font-family: "Inter", sans-serif !important;
  color: #353535 !important;
  font-weight: 500;
  font-size: 13px;
}
.about_card_text {
  font-family: "Inter", sans-serif !important;
  color: #656565 !important;
  font-weight: 600 !important;
  font-size: 13px !important;
  margin: 0;
  padding: 3px;
  font-style: italic;
}
.about_main_title {
  font-family: "Roboto", sans-serif !important;
  color: #0e082c !important;
  margin-bottom: 0;
  font-size: 40px;
  font-weight: bold;
}
.about_main_para {
  font-family: "Inter", sans-serif !important;
  color: #353535 !important;
  font-weight: 500;
  font-size: 20px;
}
.people_title {
  font-family: "Roboto", sans-serif !important;
  color: #0e082c !important;
  margin-bottom: 0;
  font-size: 25px;
  font-weight: bold;
}
.about_clickable {
  cursor: pointer;
}
.css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root {
  min-height: 68px !important;
}
.people_para {
  font-family: "Inter", sans-serif !important;
  color: #353535 !important;
  font-weight: 500;
  font-size: 16px;
  margin-top: 5px;
}
.step_input {
  width: 100%;
  height: 35px;
  border-radius: 50px;
  border: 2px solid #526355;
  font-size: 15px;
  font-weight: 600;
  color: #526355;
  display: flex;
  align-items: center;
  justify-content: center;
}
.step_card {
  border-radius: 10px !important;
  padding: 20px;
  text-align: center;
  width: 300px;
  height: 300px;
  border: 2px solid #009e21;
  display: grid;
  place-items: center;
  margin: 10px;
}
.step_title {
  font-family: "Inter", sans-serif !important;
  color: #274005 !important;
  font-weight: bold;
  font-size: 20px;
  text-transform: capitalize;
  margin-bottom: 0;
  padding: 3px;
}
.step_footer {
  font-family: "Inter", sans-serif !important;
  color: #274005 !important;
  font-weight: bold;
  font-size: 14px;
  text-transform: capitalize;
  margin-bottom: 0;
  padding: 3px;
}
.drop {
  font-family: "Inter", sans-serif !important;
  color: #274005 !important;
  font-weight: bold;
  font-size: 10px;
  text-transform: capitalize;
  margin-bottom: 0;
  padding: 3px;
}
.or {
  font-family: "Inter", sans-serif !important;
  color: #274005 !important;
  font-size: 10px;
  text-transform: lowercase;
  margin-bottom: 0;
  padding: 3px;
}
.step_img {
  height: 150px;
  width: 150px;
}
.step_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.step_box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  border: 2px solid #009e21;
  border-radius: 10px;
  padding: 10px;
  height: 100%;
  width: 100%;
}

.step_green_btn {
  background-color: #009e21 !important;
  border: 1px solid #1de346;
  color: #fff;
  font-size: 10px;
  padding: 5px;
  margin-top: 20px;
  border-radius: 5px;
}
.step_orange_btn {
  background-color: #ff6b1a !important;
  border-radius: 5px;
  border: 1px solid #ff5c02;
  color: #fff;
  font-size: 10px;
  padding: 5px;
  margin-top: 20px;
  border-radius: 5px;
}

.slick-next {
  right: 7px;
  z-index: 10000;
}
.slick-prev {
  left: 7px;
  z-index: 10000;
}

.slick-slide {
  display: flex !important;
  justify-content: center !important;
}
